$color1:#de0000; //red
$color2:#a31111; //darkred
$color3: #f8f5f5; //lightgrey
$color4: #666; //darkgrey
$color5: #ffffff; //white

$mar: 15px;
$pad: 15px;





@media only screen and (max-width : 1024px) {
  /* Styles */
  .container {
    overflow-x: hidden;
  }
  .max-width {
    overflow-x: visible !important;
  }

  .slick-arrow {
    display: block !important;
  }

  #header-logo {
    width:100%;
    height:auto;
    margin:0px;
  }
  .nav-link:link {
    padding:10px !important;
  }
  .navbar-nav .nav-item+.nav-item {
    margin-left:0px;
  }
  .home-course-wrap .home-button-2 {
    display: none;
  }
  .bezel-header h3 {
    font-size: 24px;
    padding:15px;
  }
  .home-course-bg {
    background-size: cover;

  }
  .home-course-wrap .course-image-wrap img {
    min-height:200px;
  }
  .home-course-wrap .course-image-wrap {
    height:200px;
  }
  .course-slide-image img {
    width:100%;
    height:auto;
  }
  .pad-left {
    padding-left:$pad;
  }
  .pad-right {
    padding-right: $pad;
  }
  /* Courses */
  .course-card-title {
    min-height:60px;
  }
  .home-apprenticeships-bg {
    background-size: cover;
  }
  .cal-course-img-wrap {
    margin-right:$mar;
  }


}

@media only screen and (max-width : 768px)  {
  /* Styles */
  .container {
    overflow-x: hidden;
  }

  .pad-left, .pad-right {
    padding: 0px;
  }
  .mar-left, .mar-right {
    margin:0px;
  }

  .slick-arrow {
    display: none !important;
  }
  .bezel-header img {
    display: none;
  }

  #header-logo {
    width:70%;
    display: block;
    margin:0 auto;
    height:auto;
    max-height: none;
  }
  .navbar-toggler {
    margin:10px;
    float:right;
  }
  .navbar-brand {
    display: block;
    position: relative;
    color: #FFF !important;
    padding:20px 10px;
    min-height:30px;
  }
  .navbar-nav {
    display: block;
  }
  .nav-link:hover {
    background-color: $color1;
  }
  .navbar-toggleable-md {
    border-top:2px solid $color2;
  }
  footer {
    text-align: center;
  }
  .sdlogo {
    float: none;
    margin: 10px 0px;
  }
  .footer2 .pad-right {
    padding:0px;
  }

  /* Bezels */
  .bezel-header img {
    display: block;
  }
  .bezel-header h3 {
    font-size: 20px;
    padding:10px 15px;
  }

  .header-image {
    min-height:200px;
    height:auto;}


  /* Home Page */
  .home-course-wrap .course-image-wrap img {
    min-height:230px;
  }
  .home-course-wrap .course-image-wrap {
    height:230px;
  }

  .home-course-bg {
    background-size: 100% 100%;

  }
  .home-apprenticeships-bg {
    background: none !important;
  }
  .course-slide-image img {
    width:100%;
    height:auto;
  }

  footer {
    text-align: center;
  }
  .sdlogo {
    float: none;
    margin: 10px 0px;
  }
  .footer2 .pad-right {
    padding:0px;
  }
  .hidden-sm-down {
    display: none !important;
  }

  /* Courses */
  .course-slide-image img {
    width:100%;
    height:auto;
  }
  .header-image { height:auto;}

  .course-slide-image {
    overflow: hidden;
  }
  .course-slide-image img {
    min-width:100%;
    min-height:100%;
  }
  .course-card-image img {
    min-width:100%;
  }
  .pull-left, .pull-right {
    width:100%;
    float: none;
  }
  .cal-course-img-wrap {
    margin-right:$mar;
  }
  .pad-xs {
    padding: $pad;
  }


}

@media only screen and (max-width : 600px)  {
  /* Styles */
  .container {
    overflow-x: hidden;
  }
  .small-pad-left, .small-pad-right, .pad-left, .pad-right {
    padding:0px;
  }
  .mar-left, .mar-right {
    margin:0px;
  }
  .slick-arrow {
    display: none !important;
  }
  .bezel-header img {
    display: none;
  }
  .bezel-header {
    display: none;
  }
  #header-logo {
    width:100%;
    height:auto;
    max-height: none;
  }
  .navbar-toggler {
    margin:10px;
    float:right;
  }
  .navbar-brand {
    display: block;
    position: relative;
    color: #FFF !important;
    padding:20px 10px;
    min-height:30px;
  }
  .navbar-nav {
    display: block;
  }
  .nav-link:hover {
    background-color: $color1;
  }
  .navbar-toggleable-md {
    border-top:2px solid $color2;
  }
  footer {
    text-align: center;
  }
  .sdlogo {
    float: none;
    margin: 10px 0px;
  }
  .footer2 .pad-right {
    padding:0px;
  }

  /* Home Page */
  .home-course-wrap .course-image-wrap img {
    min-height:180px;
  }
  .home-course-wrap .course-image-wrap {
    height:180px;
  }

  .home-course-bg {
    background-size: auto 100%;

  }
  .apprenticeship-bg {
    background-size: cover;
  }
  .home-apprenticeships-bg {
    background: none !important;
  }

  /* Courses */
  .course-slide-image img {
    width:100%;
    height:auto;
  }
  .header-image {
    min-height:200px;
    height:auto;}

  .course-slide-image {
    overflow: hidden;
  }
  .course-slide-image img {
    min-width:100%;
    min-height:100%;
  }
  .course-card-image img {
    min-width:100%;
  }
  .course-view-contents {
    top:0px;
  }
  .course-header-image h1 {
    top:20%;
  }
  .pull-left, .pull-right {
    width:100%;
    float: none;
  }
  .cal-course-img-wrap {
    margin-right:$mar;
  }
  .course-card-image {
    height:280px;
  }
  .pad-xs {
    padding: $pad;
  }
}
